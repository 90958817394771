import {
  LazyIconFeather,
  LazyIconLeaf,
  LazyIconLightbulb,
  LazyIconPercent,
  LazyIconSparkle,
  LazyIconTrophy,
  LazyIconKoffers,
  LazyIconLaptoptassen,
  LazyIconPaymentcard,
  LazyIconSchooltassen,
  LazyIconTassen,
  LazyIconTrolleys,
  LazyIconReistassen,
  LazyIconRugzakken,
  LazyIconArrow,
  LazyIconClock,
  LazyIconChat,
  LazyIconEmail,
  LazyIconHeadset,
  LazyIconPortemonnees,
  LazyIconStar,
  LazyIconStarHollow,
  LazyIconDelivery,
  LazyIconPackage,
  LazyIconPay,
  LazyIconReturn,
  LazyIconSecure,
  LazyIconStore,
  LazyIconTravelbags,
  LazyIconNavKoffers,
  LazyIconNavLaptoptassen,
  LazyIconNavSchooltassen,
  LazyIconNavTassen,
  LazyIconNavTrolleys,
  LazyIconNavReistassen,
  LazyIconNavRugzakken,
  LazyIconNavAccessoires,
  LazyIconNavPortemonnees,
  LazyIconPaymentMethodsAmericanExpress,
  LazyIconPaymentMethodsApplePay,
  LazyIconPaymentMethodsBancontact,
  LazyIconPaymentMethodsBillink,
  LazyIconPaymentMethodsDeCadeaukaart,
  LazyIconPaymentMethodsEps,
  LazyIconPaymentMethodsFlyingBlue,
  LazyIconPaymentMethodsGooglePay,
  LazyIconPaymentMethodsIdeal,
  LazyIconPaymentMethodsKlarna,
  LazyIconPaymentMethodsMaestro,
  LazyIconPaymentMethodsMastercard,
  LazyIconPaymentMethodsPayByBank,
  LazyIconPaymentMethodsPaypalIcon,
  LazyIconPaymentMethodsPayconiq,
  LazyIconPaymentMethodsVisa,
  LazyIconPaymentMethodsVvvGiftcard,
  LazyIconSocialMediaFacebook,
  LazyIconSocialMediaFacebook2,
  LazyIconSocialMediaInstagram,
  LazyIconSocialMediaYoutube,
  LazyIconSocialMediaTiktok,
  LazyIconSocialMediaWhatsapp,
} from '#components'

export function useStrapiIcons() {
  const icons = {
    LazyIconFeather,
    LazyIconLeaf,
    LazyIconLightbulb,
    LazyIconPercent,
    LazyIconSparkle,
    LazyIconTrophy,
    LazyIconKoffers,
    LazyIconLaptoptassen,
    LazyIconPaymentcard,
    LazyIconSchooltassen,
    LazyIconTassen,
    LazyIconTrolleys,
    LazyIconReistassen,
    LazyIconRugzakken,
    LazyIconArrow,
    LazyIconClock,
    LazyIconChat,
    LazyIconEmail,
    LazyIconHeadset,
    LazyIconPortemonnees,
    LazyIconStar,
    LazyIconStarHollow,
    LazyIconDelivery,
    LazyIconPackage,
    LazyIconPay,
    LazyIconReturn,
    LazyIconSecure,
    LazyIconStore,
    LazyIconTravelbags,
    LazyIconNavKoffers,
    LazyIconNavLaptoptassen,
    LazyIconNavSchooltassen,
    LazyIconNavTassen,
    LazyIconNavTrolleys,
    LazyIconNavReistassen,
    LazyIconNavRugzakken,
    LazyIconNavAccessoires,
    LazyIconNavPortemonnees,
    LazyIconPaymentMethodsAmericanExpress,
    LazyIconPaymentMethodsApplePay,
    LazyIconPaymentMethodsBancontact,
    LazyIconPaymentMethodsBillink,
    LazyIconPaymentMethodsEps,
    LazyIconPaymentMethodsDeCadeaukaart,
    LazyIconPaymentMethodsFlyingBlue,
    LazyIconPaymentMethodsGooglePay,
    LazyIconPaymentMethodsIdeal,
    LazyIconPaymentMethodsKlarna,
    LazyIconPaymentMethodsMaestro,
    LazyIconPaymentMethodsMastercard,
    LazyIconPaymentMethodsPayByBank,
    LazyIconPaymentMethodsPaypalIcon,
    LazyIconPaymentMethodsPayconiq,
    LazyIconPaymentMethodsVisa,
    LazyIconPaymentMethodsVvvGiftcard,

    LazyIconSocialMediaFacebook,
    LazyIconSocialMediaFacebook2,
    LazyIconSocialMediaInstagram,
    LazyIconSocialMediaYoutube,
    LazyIconSocialMediaTiktok,
    LazyIconSocialMediaWhatsapp,
  }

  function loadIcon(icon: string) {
    const base = 'LazyIcon'
    const paymentBase = base + 'PaymentMethods'
    const socialBase = base + 'SocialMedia'

    const iconName = usePascal(icon)

    switch (iconName) {
      case 'ArrowLeft':
      case 'ArrowRight':
        return LazyIconArrow
      case 'LightbulbOff':
      case 'LightbulbOn':
        return LazyIconLightbulb
      case 'Decadeaukaart':
        return LazyIconPaymentMethodsDeCadeaukaart
      case 'Paypal':
        return LazyIconPaymentMethodsPaypalIcon
      default:
        if (icons?.[base + iconName] || icons?.[paymentBase + iconName] || icons?.[socialBase + iconName]) {
          return icons[base + iconName] || icons[paymentBase + iconName] || icons?.[socialBase + iconName]
        }
        console.warn('Icon not found as: ', base + iconName, paymentBase + iconName, socialBase + iconName)
        return 'div'
    }
  }

  return {
    loadIcon,
  }
}
